// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

@import './../_mixins.scss';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@import '@angular/material/theming';

.givebrite-rg {
  --font: 'Muli';

  $givebrite-typography: mat.define-typography-config(
    $font-family: var(--font),
  );

  $ramadhan-giving: mat-palette(
    (
      10: #f8f3ff,
      50: #eae0f9,
      100: #adf2f2,
      200: #a980e7,
      300: #864ddd,
      400: #6c26d6,
      500: #0cc6da,
      600: #4b00ca,
      700: #4100c3,
      800: #3800bd,
      900: #2800b2,
      A100: #e1dbff,
      A200: #b6a8ff,
      A400: #8b75ff,
      A700: #755cff,
      contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #03324d,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #ffffff,
      ),
    )
  );

  $givebrite-blue: (
    50: #e0e5e7,
    100: #b3bdc2,
    200: #809199,
    300: #4d6570,
    400: #162e3a,
    500: #1a1c1d,
    600: #001f2e,
    700: #001a27,
    800: #001520,
    900: #000c14,
    A100: #5599ff,
    A200: #227aff,
    A400: #005fee,
    A700: #0055d4,
    contrast: (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #ffffff,
      A400: #ffffff,
      A700: #ffffff,
    ),
  );

  $givebrite-primary: mat.define-palette($ramadhan-giving);
  $givebrite-accent: mat.define-palette($givebrite-blue);

  // The warn palette is optional (defaults to red).
  $givebrite-warn: mat.define-palette(mat.$red-palette);

  $background: yellow !default;

  $givebrite-dark-theme-background: (
    background: $background,
    card: $background,
    app-bar: darken($background, 5),
    dialog: $background,
    status-bar: darken($background, 5),
  ) !default;

  // Create the theme object. A theme consists of configurations for individual
  // theming systems such as "color" or "typography".
  $givebrite-dark-theme: mat.define-dark-theme(
    (
      color: (
        foreground: $mat-dark-theme-foreground,
        background: map_merge($mat-dark-theme-background, $givebrite-dark-theme-background),
        primary: $givebrite-primary,
        accent: $givebrite-accent,
        warn: $givebrite-warn,
      ),
      typography: $givebrite-typography,
    )
  );

  @include mat.all-component-themes($givebrite-dark-theme);

  // Foreground
  --background-app-bar: #{map-get(map-get($givebrite-dark-theme, background), app-bar)};

  // Background
  --footer-background: var(--background-card);
  --navigation-background: #{map-get(map-get($givebrite-dark-theme, background), card)};
  --toolbar-background: #1a3642;
  --background-base: #03324d;

  // Colors
  --background-card: #{map-get(map-get($givebrite-dark-theme, background), card)};
  --footer-color: var(--text-color);
  --navigation-color: var(--text-color);
  --text-color: #{$light-primary-text};
  --toolbar-color: #{$light-primary-text};
  --text-color-light: #{$dark-primary-text};

  // Toolbar
  --toolbar-height: 80px;

  // Navigation
  --text-hint-light: #{$dark-disabled-text};
  --background-hover: #{map-get(map-get($givebrite-dark-theme, background), hover)};

  // Secondary Toolbar
  --text-secondary: #{$light-secondary-text};

  // Footer
  --text-secondary-light: #{$dark-secondary-text};
  --secondary-toolbar-background: var(--background-card);
}
