// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'sass:map';
@import '@angular/material/theming';

@font-face {
  font-family: 'Signika';
  font-weight: 400;
  src: url('../../../../../../../apps/charities/skt/donate/src/assets/fonts/Signika-Regular.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Signika';
  font-weight: 500;
  src: url('../../../../../../../apps/charities/skt/donate/src/assets/fonts/Signika-Medium.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Signika';
  font-weight: 600;
  src: url('../../../../../../../apps/charities/skt/donate/src/assets/fonts/Signika-SemiBold.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Signika';
  font-weight: 700;
  src: url('../../../../../../../apps/charities/skt/donate/src/assets/fonts/Signika-Bold.ttf')
    format('truetype');
}

.givebrite-skt {
  --font: 'Signika';
  $givebrite-skt-red: (
    50: #ffebee,
    100: #ffcdd2,
    200: #ef9a9a,
    300: #e57373,
    400: #ef5350,
    500: #db1915,
    600: #e53935,
    700: #d32f2f,
    800: #c62828,
    900: #b71c1c,
    A100: #ff8a80,
    A200: #ff5252,
    A400: #ff1744,
    A700: #d50000,
    contrast: (
      50: $black-87-opacity,
      100: $black-87-opacity,
      200: $black-87-opacity,
      300: $black-87-opacity,
      400: $black-87-opacity,
      500: white,
      600: white,
      700: white,
      800: $white-87-opacity,
      900: $white-87-opacity,
      A100: $black-87-opacity,
      A200: white,
      A400: white,
      A700: white,
    ),
  );

  $givebrite-blue: (
    50: #e0e5e7,
    100: #b3bdc2,
    200: #809199,
    300: #4d6570,
    400: #162e3a,
    500: #1a1c1d,
    600: #001f2e,
    700: #001a27,
    800: #001520,
    900: #000c14,
    A100: #5599ff,
    A200: #227aff,
    A400: #005fee,
    A700: #0055d4,
    contrast: (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #ffffff,
      A400: #ffffff,
      A700: #ffffff,
    ),
  );

  $givebrite-primary: mat.define-palette($givebrite-skt-red);
  $givebrite-accent: mat.define-palette($givebrite-blue);

  // The warn palette is optional (defaults to red).
  $givebrite-warn: mat.define-palette(mat.$red-palette);

  $givebrite-typography: mat.define-typography-config(
    $font-family: var(--font),
  );

  // Create the theme object. A theme consists of configurations for individual
  // theming systems such as "color" or "typography".
  $givebrite-theme: mat.define-light-theme(
    (
      color: (
        primary: $givebrite-primary,
        accent: $givebrite-accent,
        warn: $givebrite-warn,
      ),
      typography: $givebrite-typography,
    )
  );

  // @include angular-material-theme($givebrite-theme);

  @include mat.core-theme($givebrite-theme);
  @include mat.button-theme($givebrite-theme);
  @include mat.input-theme($givebrite-theme);
  @include mat.tabs-theme($givebrite-theme);
  @include mat.card-theme($givebrite-theme);
  @include mat.divider-theme($givebrite-theme);
  @include mat.progress-bar-theme($givebrite-theme);
  @include mat.form-field-theme($givebrite-theme);
  @include mat.menu-theme($givebrite-theme);
  @include mat.progress-spinner-theme($givebrite-theme);
  @include mat.datepicker-theme($givebrite-theme);
  @include mat.select-theme($givebrite-theme);
  @include mat.icon-theme($givebrite-theme);
  @include mat.snack-bar-theme($givebrite-theme);
  @include mat.list-theme($givebrite-theme);
  @include mat.slide-toggle-theme($givebrite-theme);
  @include mat.expansion-theme($givebrite-theme);
  @include mat.dialog-theme($givebrite-theme);
  @include mat.badge-theme($givebrite-theme);
  @include mat.stepper-theme($givebrite-theme);
  @include mat.button-toggle-theme($givebrite-theme);
  @include mat.autocomplete-theme($givebrite-theme);
  @include mat.radio-theme($givebrite-theme);
  @include mat.tooltip-theme($givebrite-theme);
  @include mat.checkbox-theme($givebrite-theme);
  // Background

  --footer-background: var(--background-card);
  --toolbar-background: #{map-get(map-get($givebrite-theme, accent), 400)};
  --background-base: #fcfcfc;

  // Toolbar
  --toolbar-icon-color: var(--color-secondary-contrast);
  --toolbar-color: var(--color-secondary-contrast);
  --toolbar-height: 84px;

  // header
  --hero-height: 30rem;

  //sidenav
  --sidenav-color: var(--background-base);
}
// @formatter:on
