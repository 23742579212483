// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// @import './../_mixins.scss';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@import '@angular/material/theming';
@font-face {
  font-family: 'Circular';
  font-weight: 400;
  src: url('https://s3.givebrite.com/fonts/circular-book.woff') format('woff');
}

@font-face {
  font-family: 'Oduda';
  font-weight: 400;
  src: url('https://s3.givebrite.com/fonts/oduda-regular.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-weight: 500;
  src: url('https://s3.givebrite.com/fonts/circular-medium.woff') format('woff');
}
@font-face {
  font-family: 'Circular';
  font-weight: 300;
  src: url('https://s3.givebrite.com/fonts/circular-book.woff') format('woff');
}
@font-face {
  font-family: 'Circular';
  font-weight: 700;
  src: url('https://s3.givebrite.com/fonts/circular-bold.woff') format('woff');
}
// @include mat-core();

// $first-custom-typography: mat-typography-config(
//   $font-family: 'Circular',
//   $display-4: mat-typography-level(30px, 37px, 600),
//   $display-3: mat-typography-level(36px, 43px, 600),
//   $display-2: mat-typography-level(48px, 66px, 600),
//   $display-1: mat-typography-level(48px, 58px, 800),
//   $headline: mat-typography-level(48px, 58px, 800),
//   $title: mat-typography-level(48px, 66px, 600),
//   $subheading-2: mat-typography-level(36px, 43px, 600),
//   $subheading-1: mat-typography-level(30px, 37px, 600),
//   $body-2: mat-typography-level(14px, 17px, 400),
//   $body-1: mat-typography-level(24px, 29px, 500),
//   $caption: mat-typography-level(12px, 20px, 400),
//   $button: mat-typography-level(14px, 14px, 500),
//   $input: mat-typography-level(inherit, 1.125, 400),
// );

// @include mat.core($first-custom-typography);

// $my-custom-level: mat.define-typography-level(
//   $font-family: 'Circular',
//   $font-weight: 400,
//   $font-size: 1rem,
//   $line-height: 1,
//   $letter-spacing: normal,
// );
// @include mat.core($my-custom-level);

.givebrite {
  --font: 'Circular';
  $givebrite-green: (
    50: #e1f5eb,
    100: #b3e5ce,
    200: #81d4ae,
    300: #4ec38d,
    400: #28b674,
    500: #02a95c,
    600: #050505,
    700: #01984a,
    800: #018f41,
    900: #017e30,
    A100: #acffc4,
    A200: #79ffa0,
    A400: #46ff7c,
    A700: #2cff6a,
    contrast: (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #000000,
      400: #000000,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #000000,
      A400: #000000,
      A700: #000000,
    ),
  );

  $givebrite-blue: (
    50: #e0e5e7,
    100: #b3bdc2,
    200: #809199,
    300: #4d6570,
    400: #162e3a,
    500: #1a1c1d,
    600: #001f2e,
    700: #001a27,
    800: #001520,
    900: #000c14,
    A100: #5599ff,
    A200: #227aff,
    A400: #005fee,
    A700: #0055d4,
    contrast: (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #ffffff,
      A400: #ffffff,
      A700: #ffffff,
    ),
  );

  //   // Create the theme object (a Sass map containing all of the palettes).
  //   $vex-theme: (
  //     primary: $vex-primary,
  //     accent: $vex-accent,
  //     warn: $vex-warn,
  //     is-dark: false,
  //     foreground: map_merge($mat-light-theme-foreground, $vex-theme-foreground),
  //     background: map_merge($mat-light-theme-background, $vex-theme-background),
  //   ) !default;

  $givebrite-primary: mat.define-palette($givebrite-green);
  $givebrite-accent: mat.define-palette($givebrite-blue);

  // The warn palette is optional (defaults to red).
  $givebrite-warn: mat.define-palette(mat.$red-palette);

  $givebrite-typography: mat.define-typography-config(
    $font-family: var(--font),
  );

  // Create the theme object. A theme consists of configurations for individual
  // theming systems such as "color" or "typography".
  $givebrite-theme: mat.define-light-theme(
    (
      color: (
        primary: $givebrite-primary,
        accent: $givebrite-accent,
        warn: $givebrite-warn,
      ),
      typography: $givebrite-typography,
    )
  );

  // @include angular-material-theme($givebrite-theme);

  @include mat.core-theme($givebrite-theme);
  @include mat.button-theme($givebrite-theme);
  @include mat.input-theme($givebrite-theme);
  @include mat.tabs-theme($givebrite-theme);
  @include mat.card-theme($givebrite-theme);
  @include mat.divider-theme($givebrite-theme);
  @include mat.progress-bar-theme($givebrite-theme);
  @include mat.form-field-theme($givebrite-theme);
  @include mat.menu-theme($givebrite-theme);
  @include mat.progress-spinner-theme($givebrite-theme);
  @include mat.datepicker-theme($givebrite-theme);
  @include mat.select-theme($givebrite-theme);
  @include mat.icon-theme($givebrite-theme);
  @include mat.snack-bar-theme($givebrite-theme);
  @include mat.list-theme($givebrite-theme);
  @include mat.slide-toggle-theme($givebrite-theme);
  @include mat.expansion-theme($givebrite-theme);
  @include mat.dialog-theme($givebrite-theme);
  @include mat.badge-theme($givebrite-theme);
  @include mat.autocomplete-theme($givebrite-theme);
  @include mat.checkbox-theme($givebrite-theme);
  @include mat.radio-theme($givebrite-theme);
  @include mat.tooltip-theme($givebrite-theme);
  // Background

  --footer-background: var(--background-card);
  --toolbar-background: #{map-get(map-get($givebrite-theme, accent), 400)};
  --background-base: #fff;

  // Toolbar
  --toolbar-icon-color: var(--color-secondary);
  --toolbar-color: var(--color-secondary-contrast);
  --toolbar-height: 84px;

  // header
  --hero-height: 30rem;

  //sidenav
  --sidenav-color: var(--background-base);

  // campaign card
  --campaign-card-width: 16rem;
  --campaign-card-height: 23rem;
  --campaign-card-img-height: 9rem;
  --campaign-card-button-width: 47%;

  --charity-icon-height: 4rem;
  --charity-icon-width: 4rem;

  --fundraisers-header-height: 24rem;
  --fundraisers-header-height-mobile: 19rem;

  --fundraisers-feature-card-width: 12.5rem;
  --fundraisers-feature-card-height: 20rem;
  --fundraisers-feature-card-width-sm: 180px;
  --fundraisers-feature-card-width-xs: 160px;
  --fundraiser-common-height: 1.8rem;
  --default-icon-size: 20px;

  // progress-bar
  --progress-bar-width: 216px;

  .mat-stroked-button.mat-primary {
    border-width: 2px;
    border-color: var(--color-primary);
  }
}
// @formatter:on
