$mat-elevation-color: #523f68;
$mat-elevation-opacity: 0.3;
$font-family: Lato;

@function vex-elevation($zValue, $color: $mat-elevation-color, $opacity: $mat-elevation-opacity) {
  @if type-of($zValue) != number or not unitless($zValue) {
    @error '$zValue must be a unitless number';
  }
  @if $zValue < 0 or $zValue > 24 {
    @error '$zValue must be between 0 and 24';
  }

  @return #{map-get(_get-umbra-map($color, $opacity), $zValue)},
    #{map-get(_get-penumbra-map($color, $opacity), $zValue)},
    #{map-get(_get-ambient-map($color, $opacity), $zValue)};
}

// main overwrite to make it all work
@function mat-color($palette, $hue: default, $opacity: null) {
  @if type-of($hue) == number and $hue >= 0 and $hue <= 1 {
    @return mat-color($palette, default, $hue);
  }

  $color: map-get($palette, $hue);

  @if (type-of($color) != color) {
    @if ($opacity == null) {
      @return $color;
    }

    // Here is the change from the original function:
    // If the $color resolved to something different from a color, we assume it is a CSS variable
    // in the form of rgba(var(--rgba-css-var),a) and replace the 'a' value.
    @return #{str-slice($color, 0, str-index($color, ',')) + $opacity + ')'};
  }

  @return rgba($color, if($opacity == null, opacity($color), $opacity));
}

// also needs to be overwritten, as otherwise we end up with opacity and alpha value
@mixin mat-ripple-theme($theme) {
  $foreground: map_get($theme, foreground);
  $foreground-base: map_get($foreground, base);

  .mat-ripple-element {
    // if it's a color, rgba just works.
    // If it's a variable, rgba works because all color variables should be 3 comma separated numbers
    background-color: rgba($foreground-base, $mat-ripple-color-opacity);
  }
}

// The material mixin passes an opacity to mat-color and also adds opacity to the element if the background-color is not of type 'color'.
// This would cause the opacity to get applied twice, resulting in a ripple that is almost invisible.
// Instead, we will trust that mat-color handles opacity correctly.
@mixin _mat-button-ripple-background($palette, $hue, $opacity) {
  $background-color: mat-color($palette, $hue, $opacity);
  background-color: $background-color;
}

// for Mobile Screen
@mixin res-phone {
  @media only screen and (max-width: 480px) {
    @content;
  }
}

@mixin max-width($arg) {
  @media only screen and (max-width: $arg) {
    @content;
  }
}

// buttons

@mixin small-size-button {
  font-family: $font-family;
  width: 6.25rem;
  height: 2rem;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

@mixin medium-size-button {
  font-family: $font-family;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  height: 47px;
  width: 178px;

  @include res-phone {
    width: 130px;
    height: 41px;
    font-size: 13.5px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@mixin medium-small-size-button {
  font-family: $font-family;
  border: 1.5px solid white;
  box-sizing: border-box;
  width: 137.2px;
  height: 45px;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;

  @include res-phone {
    width: 125px;
    height: 41px;
    font-size: 13.5px;
    line-height: 16px;
  }
}

/* FULL WINDOW DIALOG */
@mixin full-window-dialog(
  $mobileWidth: 599px,
  $width: 30rem,
  $padding-top: 36px,
  $padding: 0px,
  $border-radius: inherit,
  $border: 0px
) {
  width: $width;
  padding: 0;

  .mat-dialog-container {
    padding-top: $padding-top;
    background: white;
    box-shadow: none;
    overflow: hidden;
    border-radius: $border-radius;

    @media only screen and (max-width: $mobileWidth) {
      padding-top: 0px;
    }
  }

  @media only screen and (max-width: $mobileWidth) {
    max-width: 100vw !important;
    width: 100%;
    height: 100%;
    .mat-dialog-container {
      max-width: 100vw;
      max-height: 100vh;
      padding: $padding;
      border-radius: $border;
      .mat-dialog-content {
        max-height: 100%;
        padding-bottom: 50px;
      }
    }
  }
}

/* TRANSPARENT BACKGROUND */
@mixin transparent-container {
  .mat-dialog-container {
    background: transparent !important;
    box-shadow: none;
  }
}

/* CLOSE ICON */
@mixin close($close: #1a3642) {
  font-size: 24px;
  width: fit-content;
  height: fit-content;
  margin-left: auto;
  margin-right: 0;
  padding: 0.2rem;

  &:hover {
    border-radius: 4px;
    background: $close;
  }
}

/* TRUNCATE TEXT */
@mixin truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* CUSTOM SCROLLBAR */
@mixin custom-scrollbar {
  ::-webkit-scrollbar {
    width: 10px;
    overflow-y: scroll;
    background: #ffffff;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px #d3dce8;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #d3dce8;
    border-radius: 10px;
  }
}

@mixin buttom-fixed-dialog($mobileWidth: $mobile-screen) {
  @media only screen and (max-width: $mobileWidth) {
    position: fixed !important;
    bottom: 0px;
  }
}

@mixin back-button {
  button {
    font-size: 20px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 8px;
    font-weight: 500;

    @screen sm {
      font-size: 24px;
      font-weight: 700;
    }
  }

  .next-icon {
    font-size: 18px;
    font-weight: 500;
    position: absolute;
    right: 1.1rem;
    top: 16px;
    left: 1.5rem;

    @screen sm {
      font-size: 24px;
      font-weight: 700;
      top: 22px;
      right: 1.5rem;
    }
  }

  .back-btn {
    background: #f2f8fd;
    border: 1px solid #757575;
    color: #757575;
  }

  .back-icon {
    left: 1.1rem;

    @screen sm {
      left: 1.5rem;
    }
  }
}

/* DIALOG CONTAINER */
@mixin dialog-container($skt: 'false') {
  background-color: var(--dialog-primary-color);
  color: var(--dialog-secondary-color);

  @if $skt == 'true' {
    border-radius: 4px;
    @apply h-full;
  } @else {
    @apply rounded-none p-4 h-full;

    @screen sm {
      border-radius: 4px;
      @apply p-8;
    }
  }

  h1 {
    font-weight: 600;

    @if skt == 'true' {
      font-size: 1.5rem;
    } @else {
      font-size: 2rem;
    }
  }

  p {
    font-size: 1rem;
  }
}

/* DONATE DIALOG */
@mixin donate-dialog($mobileWidth: $mobile-screen, $width: $dialog-width) {
  width: $width;
  padding: 0;

  .mat-dialog-container {
    padding-top: 24px;
    background: white;
    box-shadow: none;
    overflow: hidden;
  }

  @media only screen and (max-width: $mobileWidth) {
    max-width: 94vw !important;
    width: 100%;

    .mat-dialog-container {
      max-width: 94vw;
      padding: 16px;
      padding-top: 24px;
    }
  }
}

/**
 @description
 PROGRESS BAR - It requires a class called givebrite-progress-bar
 as parent to the <mat-progress-bar></mat-progress-bar>
*/

@mixin progress-bar($buffer-color: rgba(2, 169, 92, 0.25), $background-color: #02a95c) {
  .givebrite-progress-bar {
    .mat-mdc-progress-bar {
      border-radius: 20px;
      --mdc-linear-progress-active-indicator-height: 14px !important;
      --mdc-linear-progress-track-height: 14px !important;
    }

    .mdc-linear-progress__buffer-bar {
      background: $buffer-color !important;
    }

    .mdc-linear-progress__bar-inner {
      border: none !important;
    }

    .mdc-linear-progress__bar {
      background-color: $background-color !important;
    }
  }
}
