// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

@import './../_mixins.scss';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@import '@angular/material/theming';

// @include mat-core();

// $first-custom-typography: mat-typography-config(
//   $font-family: Lato,
//   $display-4: mat-typography-level(30px, 37px, 600),
//   $display-3: mat-typography-level(36px, 43px, 600),
//   $display-2: mat-typography-level(48px, 66px, 600),
//   $display-1: mat-typography-level(48px, 58px, 800),
//   $headline: mat-typography-level(48px, 58px, 800),
//   $title: mat-typography-level(48px, 66px, 600),
//   $subheading-2: mat-typography-level(36px, 43px, 600),
//   $subheading-1: mat-typography-level(30px, 37px, 600),
//   $body-2: mat-typography-level(14px, 17px, 400),
//   $body-1: mat-typography-level(24px, 29px, 500),
//   $caption: mat-typography-level(12px, 20px, 400),
//   $button: mat-typography-level(14px, 14px, 500),
//   $input: mat-typography-level(inherit, 1.125, 400),
// );

// @include mat.core($first-custom-typography);

.givebrite-dark {
  --toolbar-icon-color: white;

  --font: 'Circular';

  $givebrite-typography: mat.define-typography-config(
    $font-family: var(--font),
  );

  $givebrite-green: (
    50: #e1f5eb,
    100: #b3e5ce,
    200: #81d4ae,
    300: #4ec38d,
    400: #28b674,
    500: #02a95c,
    600: #050505,
    700: #01984a,
    800: #018f41,
    900: #017e30,
    A100: #acffc4,
    A200: #79ffa0,
    A400: #46ff7c,
    A700: #2cff6a,
    contrast: (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #000000,
      400: #000000,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #000000,
      A400: #000000,
      A700: #000000,
    ),
  );

  $givebrite-blue: (
    50: #e0e5e7,
    100: #b3bdc2,
    200: #809199,
    300: #4d6570,
    400: #162e3a,
    500: #1a1c1d,
    600: #001f2e,
    700: #001a27,
    800: #001520,
    900: #000c14,
    A100: #5599ff,
    A200: #227aff,
    A400: #005fee,
    A700: #0055d4,
    contrast: (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #ffffff,
      A400: #ffffff,
      A700: #ffffff,
    ),
  );

  //   // Create the theme object (a Sass map containing all of the palettes).
  //   $vex-theme: (
  //     primary: $vex-primary,
  //     accent: $vex-accent,
  //     warn: $vex-warn,
  //     is-dark: false,
  //     foreground: map_merge($mat-light-theme-foreground, $vex-theme-foreground),
  //     background: map_merge($mat-light-theme-background, $vex-theme-background),
  //   ) !default;

  $givebrite-primary: mat.define-palette($givebrite-green);
  $givebrite-accent: mat.define-palette($givebrite-blue);

  // The warn palette is optional (defaults to red).
  $givebrite-warn: mat.define-palette(mat.$red-palette);

  $background: yellow !default;

  $givebrite-dark-theme-background: (
    background: $background,
    card: $background,
    app-bar: darken($background, 5),
    dialog: $background,
    status-bar: darken($background, 5),
  ) !default;

  // Create the theme object. A theme consists of configurations for individual
  // theming systems such as "color" or "typography".
  $givebrite-dark-theme: mat.define-dark-theme(
    (
      color: (
        foreground: $mat-dark-theme-foreground,
        background: map_merge($mat-dark-theme-background, $givebrite-dark-theme-background),
        primary: $givebrite-primary,
        accent: $givebrite-accent,
        warn: $givebrite-warn,
      ),
      typography: $givebrite-typography,
    )
  );

  @include mat.all-component-themes($givebrite-dark-theme);

  // @include angular-material-theme($givebrite-dark-theme);
  // @include mat.core-theme($givebrite-dark-theme);
  // @include mat.button-theme($givebrite-dark-theme);
  // @include mat.card-theme($givebrite-dark-theme);
  // @include mat.input-theme($givebrite-dark-theme);
  // @include mat.form-field-theme($givebrite-dark-theme);

  // Foreground
  --background-app-bar: #{map-get(map-get($givebrite-dark-theme, background), app-bar)};

  // Background
  --footer-background: var(--background-card);
  --navigation-background: #{map-get(map-get($givebrite-dark-theme, background), card)};
  --toolbar-background: #1a3642;
  --background-base: #002333;

  // Colors
  --background-card: #{map-get(map-get($givebrite-dark-theme, background), card)};
  --footer-color: var(--text-color);
  --navigation-color: var(--text-color);
  --text-color: #{$light-primary-text};
  --toolbar-color: #{$light-primary-text};
  --text-color-light: #{$dark-primary-text};

  // Toolbar
  --foreground-divider: #{map-get(map-get($givebrite-dark-theme, foreground), divider)};
  --text-hint: #{$light-disabled-text};

  // Navigation
  --text-hint-light: #{$dark-disabled-text};
  --background-hover: #{map-get(map-get($givebrite-dark-theme, background), hover)};

  // Secondary Toolbar
  --text-secondary: #{$light-secondary-text};

  // Footer
  --text-secondary-light: #{$dark-secondary-text};
  --secondary-toolbar-background: var(--background-card);
}
