/* You can add global styles to this file, and also import other style files */
@import './../../../libs/theming/src/lib/styles/core.scss';

:root {
  --color-secondary: #002333;
  --color-secondary-light: #1a3642;
  --color-primary-brighter: #04ff84;
  --font: 'Circular';

  body {
    font-family: var(--font), sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  span,
  div,
  button {
    font-family: var(--font) !important;
  }

  @media screen and (max-width: 599px) {
    input,
    select,
    textarea {
      font-size: 16px;
    }
  }

  .lottie-dialog {
    .mat-mdc-dialog-container {
      box-shadow: none !important;
      background: transparent !important;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
  .spinner > span.mat-option-text {
    display: flex;
    justify-content: center;
  }

  .mat-expansion-panel {
    // background: #36cc7c;
    border-radius: 50px !important;
  }
}

.content {
  background: transparent !important;
}

body {
  background: #dcf3fe;
  overflow-x: hidden;
}

.arrow-icon-right {
  font-size: 24px;
  position: absolute;
  right: 0px;
  top: 8px;
  right: 15px;
}

// Custom toggle css
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
// .mat-checked .mat-slide-toggle-bar {
//   background-color: #ef716d !important;
// }
// /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
// .mat-slide-toggle-bar {
//   width: 31.26px !important;
//   height: 19px !important;
//   border-radius: 15px !important;
// }
// /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
// .mat-slide-toggle-thumb-container {
//   width: 16px !important;
//   height: 16px !important;
// }
// /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
// .mat-slide-toggle-thumb {
//   width: 16px !important;
//   height: 16px !important;
// }
// /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
// .mat-mdc-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
//   background-color: #ffffff !important;
// }
// /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
// .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
//   transform: translate3d(13px, 0, 0) !important;
// }
// /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
// .mat-slide-toggle-thumb-container {
//   top: 1.4px !important;
//   left: 1px !important;
// }

// SLIDER START
.mdc-switch {
  width: 51px !important;
  height: 30px !important;
  border-radius: 15px !important;
  background: #36cc7c !important;
}

.mdc-switch__track {
  height: 30px !important;
  border-radius: 15px !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background: #36cc7c !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon,
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
  display: none;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after,
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
  background: white;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50px !important;
}

// SLIDER END

.mdc-notched-outline--notched .mdc-notched-outline__notch {
  border-right: 0px;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  width: max(12px, var(--mdc-shape-small, 8px)) !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  background: transparent !important;
  box-shadow: none !important;
  max-width: 560px !important;
}

.mat-mdc-raised-button.btn-xl,
.mat-mdc-outlined-button.btn-xl,
.mat-mdc-button.btn-xl {
  line-height: 58px;
  padding: 0px 24px;
  font-size: 20px;
}

.mat-mdc-raised-button.btn-md,
.mat-mdc-outlined-button.btn-md,
.mat-mdc-button.btn-md {
  line-height: 38px;
  padding: 0px 14px;
  font-size: 16px;
}

.mat-mdc-raised-button.btn-xs,
.mat-mdc-outlined-button.btn-xs,
.mat-mdc-button.btn-xs {
  --xs-button-size: 12px;
  font-size: var(--xs-button-size);
  line-height: 24px;
  padding: 0 12px;
  min-width: 30px;
  mat-icon {
    height: calc(var(--xs-buton-size) + 2px);
    width: calc(var(--xs-buton-size) + 2px);
    font-size: calc(var(--xs-buton-size) + 4px);
  }
}

.mat-mdc-raised-button.btn-sm,
.mat-mdc-outlined-button.btn-sm,
.mat-mdc-button.btn-sm {
  --xs-button-size: 14px;
  font-size: var(--xs-button-size);
  line-height: 24px;
  padding: 0 4px;
  min-width: 30px;
  mat-icon {
    height: calc(var(--xs-buton-size) + 4px);
    width: calc(var(--xs-buton-size) + 4px);
    font-size: calc(var(--xs-buton-size) + 6px);
    vertical-align: middle;
  }
}

.mat-expansion-indicator::after {
  position: relative;
  top: -4px;
}

/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
.mat-autocomplete-panel,
.mat-select-panel {
  background: #f4f4f4;
}

.btn-arrow {
  mat-icon {
    height: auto;
    width: auto;
    position: relative;
    top: 1px;
    left: 3px;
  }
}

// PROGRESS BAR
@include progress-bar;
