@tailwind base;
input,
textarea {
  box-sizing: content-box;
  line-height: 1.5;
}

button:focus {
  outline: unset !important;
}

code {
  background: var(--background-app-bar);
  border-radius: var(--border-radius);
  color: var(--text-color);
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 85%;
  padding: 0.2em 0.4em;
}

blockquote {
  background: theme("backgroundColor.primary.light");
  border-left: 3px solid theme("backgroundColor.primary.DEFAULT");
  color: rgba(0, 0, 0, 0.87);
  font-style: normal;
  margin: 1em 0 1.5em;
  padding: 1em 1.5em;
}
blockquote > * {
  margin: 0;
}

@tailwind components;
@tailwind utilities;
/* FULL WINDOW DIALOG */
/* TRANSPARENT BACKGROUND */
/* CLOSE ICON */
/* TRUNCATE TEXT */
/* CUSTOM SCROLLBAR */
/* DIALOG CONTAINER */
/* DONATE DIALOG */
/**
 @description
 PROGRESS BAR - It requires a class called givebrite-progress-bar
 as parent to the <mat-progress-bar></mat-progress-bar>
*/
@screen lg {
  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: theme("screens.lg");
    width: 100%;
  }
}

.card {
  @apply rounded shadow-8 bg-card;
}

.avatar {
  @apply h-10 w-10 object-cover rounded-full bg-hover;
}

.hidden-input {
  @apply border-2 border-solid border-transparent py-1 px-3 rounded;
}
.hidden-input:focus {
  @apply border-primary outline-none;
}
.hidden-input.empty {
  @apply border-primary;
}

.list-item {
  @apply rounded h-12 px-4 cursor-pointer;
}

.list-item:hover {
  @apply bg-hover;
}

.textarea-clean {
  resize: none;
}
.textarea-clean:focus {
  outline: none;
}

.bg-pattern {
  background: linear-gradient(135deg, var(--background-base) 22px, var(--background-hover) 22px, var(--background-hover) 24px, transparent 24px, transparent 67px, var(--background-hover) 67px, var(--background-hover) 69px, transparent 69px), linear-gradient(225deg, var(--background-base) 22px, var(--background-hover) 22px, var(--background-hover) 24px, transparent 24px, transparent 67px, var(--background-hover) 67px, var(--background-hover) 69px, transparent 69px) 0 64px;
  background-color: var(--background-base);
  background-size: 64px 128px;
}

.trans-ease-out {
  transition: all 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.trans-shadow {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.vex-blogpost p {
  @apply my-3;
}

.mat-raised-button.btn-xl,
.mat-stroked-button.btn-xl,
.mat-button.btn-xl {
  line-height: 58px;
  padding: 0px 24px;
  font-size: 20px;
}

.mat-raised-button.btn-md,
.mat-stroked-button.btn-md,
.mat-button.btn-md {
  line-height: 38px;
  padding: 0px 14px;
  font-size: 16px !important;
}

.mat-raised-button.btn-lg,
.mat-stroked-button.btn-lg,
.mat-button.btn-lg {
  line-height: 48px;
  padding: 0px 18px;
  font-size: 18px !important;
}

.mat-raised-button.btn-xs,
.mat-stroked-button.btn-xs,
.mat-button.btn-xs {
  --xs-button-size: 12px;
  font-size: var(--xs-button-size);
  line-height: 24px;
  padding: 0 12px;
  min-width: 30px;
}
.mat-raised-button.btn-xs mat-icon,
.mat-stroked-button.btn-xs mat-icon,
.mat-button.btn-xs mat-icon {
  height: calc(var(--xs-buton-size) + 2px);
  width: calc(var(--xs-buton-size) + 2px);
  font-size: calc(var(--xs-buton-size) + 4px);
}

.mat-raised-button.btn-sm,
.mat-stroked-button.btn-sm,
.mat-button.btn-sm {
  --xs-button-size: 14px;
  font-size: var(--xs-button-size);
  line-height: 24px;
  padding: 0 4px;
  min-width: 30px;
}
.mat-raised-button.btn-sm mat-icon,
.mat-stroked-button.btn-sm mat-icon,
.mat-button.btn-sm mat-icon {
  height: calc(var(--xs-buton-size) + 4px);
  width: calc(var(--xs-buton-size) + 4px);
  font-size: calc(var(--xs-buton-size) + 6px);
  vertical-align: middle;
}